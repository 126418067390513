//max out the body width to prevent crazy stretching on 4K monitors
$body_max_width : 2880px !default; //width of a hi res imac

//max-width of .container
$container_width: 75rem !default;

//where to max out full screen items (2880 = imac retina monitor)
$container_xl_width : 2880px !default; 

//the space between columns
$gutter : 1rem !default;

//number of columns in the grid
$num_cols : 24 !default;

//huge monitors. Doesn't affect the grid, but can be used for other break points
$large_break : 1920px !default;

//the break point below which all medium- grid classes override large- classes
$medium_break : 1024px !default;

//the break point below which all small- grid classes override large- and medium- classes
$small_break : 640px !default;

//breakpoint for targeting smaller 16:9 monitors
$short_break : 768px !default;

//breakpoint for switching to touch mode.
$mobile_break : 1000px !default;

//whether or not to use a flexbox grid or a floated grid
$flexbox : true !default;

//the ADA functions take a lot of time to run, so this is off by default
//set it to true when you're ready to debug ada stuff, but leave it false
//when developing, or you'll be waiting forever for each compile
$ada_colors : false !default;

//when running ada_comply functions this governs the most a color will be lightened or darkened in each recursive step
//the lower the number, the closer the resulting colors will match their inputs while still being compliant, but the slower your
//SASS will compile.
//Ideally you keep this number low, but leave ada_colors as false til you're ready to deploy
$max_contrast_step : 80% !default;

//the background colour of the mobile menu generated by <pageset />
$mmenu_bg_color : #ededed !default;

//the colour of links in the mobile menu
$mmenu_link_color : #333 !default;

//the colour of lines in the mobile menu
$mmenu_line_color : rgba($mmenu_link_color,.15) !default;

//the approximate blue of the default browser focus ring
$focus_color : #88c0fb;

$animation_easing : ease-in-out !default;
//if bourbon is being used, default to ease-in-out-quad

//I (Raymond) tend to use the same common class and ID names on every site for high level components
//when this is true they will be affected in the print styles.
//set this to false if you have conflicting class names to my own
$toggle_common_classes_on_print : true;

//the height of the adminbar when only one line
$adminbar_initial_height : 50px;

//the various responsive heights of the adminbar, from shortest to tallest and the width at which they break to that height
$adminbar_break1 : (
    width : 999px,
    height : 100px
) !default;

$adminbar_break2 : (
    width : 748px,
    height: 141px
) !default;

$adminbar_break3 : (
    width : 647px,
    height: 181px
) !default;

$adminbar_break4 : (
    width : 429px,
    height: 221px
) !default;

//@debug $ease-in-out-quad;
//@debug variable-exists(ease-in-out-quad);

@if(variable-exists(ease-in-out-quad))
{
    $animation_easing : $ease-in-out-quad !default;
}
@else
{
    @debug "Bourbon not included, default animation set to #{$animation_easing}";
}

//font weights named!
$fw_thin : 100;
$fw_extra_light : 200;
$fw_extralight : $fw_extra_light;
$fw_light : 300;
$fw_regular : 400;
$fw_medium : 500;
$fw_semibold : 600;
$fw_semi_bold : $fw_semibold;
$fw_bold : 700;
$fw_extra_bold : 800;
$fw_extrabold : $fw_extra_bold;
$fw_black : 900;

//the way the admin modals are z-indexed is weird and clashes with 
//this framework somehow. This fixes that for most sites
$modal_zindex : 999 !default;