.GameListItem
{
    list-style: none;
    padding: .2em;
    margin: 0;// 0 .25em;
    
    .GameListItem
    {
        font-size: .9em;
    }
}
.GameListItem--highlight
{
    background: $accent;
}
.GameListItem__header
{
    display: flex;
    justify-content: space-between;
    align-items: center;

    button
    {
        @include padding(horizontal,.4em);
    }
}
a.GameListItem__title
{
    font-weight: bold;
    font-size: 1.1em;
    color: $background;
    background: transparent;
}
.GameListItem__title
{
    flex-grow: 1;
}
input.GameListItem__title
{
    width: auto;
}
.Game .GameListItem__title
{
    color: $accent;
}

.Games .GameListItem__title
{
    color: $background;
    border-color: $background;
}

span.GameListItem__title
{
    cursor: pointer;
}

.GameListItem__copy-btn
{
    @include no-button-style;
    cursor: pointer;
    color: $color;
    background: transparent !important;
}
.GameListItem__copy-btn,
.GameListItem .DeleteButton,
.GameListItem .EditButton
{
    &:hover,
    &:hover .fa,
    & .fa:hover
    {
        opacity: 1;
        color: #fff;
        background: transparent;
    }
}