@import url("https://fonts.googleapis.com/css2?family=Arbutus&family=Kanit:wght@400;500&display=swap");
//@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);

@import "variables";

@import "C:/Users/Raymond/Dropbox/server/common/scss-lib/bourbon/bourbon";
@import "../../../../../common/scss-lib/framework/mixins";
@import "C:/Users/Raymond/Dropbox/server/common/scss-lib/framework/framework";

body,
html {
  font-family: $kanit;
  font-size: 16px;
  color: $color;
  background-color: $background;
  height: 100vh;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &,
  & * {
    font-weight: $fw_light;
  }

  input,
  button {
    line-height: 1.35;
  }
}

.index--body,
.App--GameCard {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $gold;
    margin: 0 1px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 0 2px;
  }
}

::selection {
  background-color: rgba($gold, 0.9);
  color: $color;
}

table {
  td,
  th {
    padding: 0.2em 0.4em;
  }

  tr:nth-child(even) {
    background: rgba($color, 0.05);
  }

  thead {
    background: rgba($color, 0.2);
  }
}

a {
  @include default_link_color($accent, darkgreen);
  @include animated(color);
}

.editable {
  border: none;
  border-radius: 0;
  border-bottom: 2px solid transparent;
  background: transparent;

  &:not([disabled]) {
    border-bottom-color: $color;
    font-weight: bold;
  }
}

button,
.button-link {
  //border: 1px solid #999;
  padding: 0.3em 0.8em;
  border-radius: 2px;
  background: $accent;
  color: #fff;
  @include animated;

  &:hover {
    background: $background;
    color: $color;
  }
}
.button-link {
  display: inline-block;
  text-decoration: none !important;
  color: $color !important;
}

.link-btn,
.link-button {
  @include no-button-style;
  text-decoration: underline;
  color: $color;
  background: transparent !important;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: $phone_width) {
  a {
    display: inline-block;
    padding: 0.2em 0.6em;
    background: #ddd;
    color: $color;
    text-decoration: none;
  }
}

@mixin mobile-only {
  @media (max-width: 820px) {
    @content;
  }
}

@mixin desktop-only {
  @media (min-width: 821px) {
    @content;
  }
}

@import "components/App";
@import "components/GameListItem";
@import "components/GameList";
@import "components/EditButton";
@import "components/DeleteButton";
@import "components/RulesList";
@import "components/AddRulesForm";
@import "components/Game";
@import "components/Desktop";
@import "components/Games";
@import "components/RuleRow";
@import "components/Loader";
@import "components/Login";
@import "components/Alert";
@import "components/Signup";
@import "components/AddGameForm";
@import "components/ComboCounter";
@import "components/SessionManager";
@import "components/ShortcutsModal";
@import "components/GameMultiSelect";
@import "components/GameAutoComplete";
@import "components/GamePill";
@import "components/EyeButton";
@import "components/GameCardFreeSquare";
@import "components/GameCard";
@import "components/GameCardSettingsModal";
@import "components/Modal";
@import "components/FieldSet";
@import "components/Slider";
@import "components/GridPicker";
@import "components/GroupModal";
@import "components/search/Index";
@import "components/search/Result";
@import "components/Toastify";
@import "../components/GameMenuModal/GameMenuModal";

.animation-test {
  animation-duration: 1s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
