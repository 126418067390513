//gulp sass doesn't have a pow function this is a polyfill
@import "pow-polyfill";
@import "../bourbon/addons/_timing-functions.scss";

//removes a link's decoration and standardizes its colour across all its states
@mixin no-decoration(
  $color: null,
  $focus: true,
  $active: true,
  $hover: true,
  $link: true,
  $visited: true
) {
  $states: (
    focus: $focus,
    active: $active,
    hover: $hover,
    link: $link,
    visited: $visited,
  );

  text-decoration: none;

  @if $color != null {
    color: $color;
  }

  @each $state, $active in $states {
    @if ($active == true) {
      &:#{$state} {
        text-decoration: none;

        @if $color != null {
          color: $color;
        }
      }
    }
  }
}

//adds the transition property to an element with some overidable defaults
//$ease-in-out-quad depends on bourbon being included
//@see ../bourbon/bourbon.scss (http://bourbon.io/)
@mixin animated($property: all, $speed: 0.2s, $easing: null, $delay: 0s) {
  @if ($easing == null) {
    $easing: $animation_easing;
  }
  transition: $property $speed $easing $delay;
}

//adds a font awesome icon to an element's before or after pseudo element
//fontawesomse-icons is Font Awesome's variable file from their own scss build and contains all the slugs as variables

@import "fontawesome-icons";

@mixin font-awesome($char, $side: null, $margin: 0.2em) {
  @if ($side == null) {
    content: $char;
    font-family: fontAwesome;
  } @else {
    &:#{$side} {
      content: $char;
      font-family: fontAwesome;
      display: inline-block;
      speak: none;

      @if ($side == after) {
        margin-left: $margin;
      } @else {
        margin-right: $margin;
      }
    }
  }
}

//changes an element containing text into an icon
@mixin iconify($char, $side: after) {
  text-indent: -9999em;
  overflow: hidden;
  display: inline-block;

  &:#{$side} {
    //font-family: fontAwesome !important;
    text-indent: 0;
    @include font-awesome($char);
    float: left;
  }

  .firefox & {
    display: block;
  }
}

//use on the wrapping element of any floated elements to create a clear fix without actually having to add
// an extra html element to the markup
@mixin clear-fix() {
  &:after {
    display: table;
    content: "";
    clear: both;
  }
}

//sets an input's placeholder colour
@mixin placeholder-color($color) {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $color;
  }
  &::-ms-input-placeholder {
    /* Edge */
    color: $color;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
  }
}

@mixin target-content-portlet {
  div[id*="ContentPlaceHolder"][id*="divContent"] {
    @content;
  }
}

@mixin style-placeholder {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    @content;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    @content;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    @content;
  }
  &::-ms-input-placeholder {
    /* Edge */
    @content;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    @content;
  }
}

@mixin placeholder-style(
  $color: inherit,
  $font-size: 1em,
  $font-weight: inherit,
  $font-style: normal
) {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color;
    font-size: $font-size;
    font-weight: $font-weight;
    font-style: $font-style;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
    font-size: $font-size;
    font-weight: $font-weight;
    font-style: $font-style;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $color;
    font-size: $font-size;
    font-weight: $font-weight;
    font-style: $font-style;
  }

  &::-ms-input-placeholder {
    /* IE 10+ */
    color: $color;
    font-size: $font-size;
    font-weight: $font-weight;
    font-style: $font-style;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
    font-size: $font-size;
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

//Sets the gutters of a row by setting its columns' padding and the row's negative margin
@mixin set-gutter($new_spacing: $gutter) {
  //@if($flexbox == false)
  //{
  @media (min-width: $container_width) {
    margin-left: (-1 * $new_spacing) / 2;
    margin-right: (-1 * $new_spacing) / 2;
  }
  //}

  > .column {
    padding-left: $new_spacing / 2;
    padding-right: $new_spacing / 2;
  }
}

//turns a list and its list items into a bunch of marginless paddingless block elements
@mixin block-list($include-display: true) {
  &,
  li {
    padding: 0;
    margin: 0;
    list-style: none;

    @if ($include-display == true) {
      display: block;
    }
  }
}

//
//  calculates the luminance contrast between two colors
//
@function ada_contrast($color1, $color2) {
  $lum1: luminance($color1) + 0.05;
  $lum2: luminance($color2) + 0.05;

  @return max($lum1, $lum2) / min($lum1, $lum2);
}

//
// Takes 3 colours- a background colour, a dark variant and a light variant
// if the background is lighter than .5 lum returns the dark variant
// else returns the light variant.
//
// Use this when a text's background colour is set already, but the text still needs to contrast
//
@function ada_color($bg, $dark: #000, $light: #fff) {
  @if (luminance($bg) > 0.5) {
    @return $dark;
  } @else {
    @return $light;
  }
}

//
//  Takes 2 colors and a percentage amount and increases the
//  contrast between the 2 colors depending on if the the first
//  color is lighter or darker than the second
//
@function increase_contrast($color, $relative_to, $amount: $contrast_step) {
  $color_lum: luminance($color);
  $relative_lum: luminance($relative_to);

  @if ($color_lum >= $relative_lum) {
    @if ($color_lum == 1) {
      @return #fff;
    } @else {
      @return lighten($color, $amount);
    }
  } @else {
    @if ($color_lum == 0) {
      @return #000;
    } @else {
      @return darken($color, $amount);
    }
  }
}

/// Returns the luminance of `$color` as a float (between 0 and 1)
/// 1 is pure white, 0 is pure black
/// @param {Color} $color - Color
/// @return {Number}
/// @link http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef Reference
@function luminance($color) {
  //@debug $color;

  $colors: (
    "red": red($color),
    "green": green($color),
    "blue": blue($color),
  );

  @each $name, $value in $colors {
    $adjusted: 0;
    $value: $value / 255;

    @if $value < 0.03928 {
      $value: $value / 12.92;
    } @else {
      $value: ($value + 0.055) / 1.055;
      $value: math-pow-polyfill($value, 2.4);
    }

    $colors: map-merge(
      $colors,
      (
        $name: $value,
      )
    );
  }

  @return (map-get($colors, "red") * 0.2126) +
    (map-get($colors, "green") * 0.7152) + (map-get($colors, "blue") * 0.0722);
}
//takes a background colour and 2 text colours.
//If the text color complies with the background
//returns background and text
//If the text and bg don't comply, tries the alt text colour with the bg and returns those if they comply
//if the alt doesnt comply either, returns the result of ada_comply_both passing background and the color
//of the 2 text options that most already contrasts with the background
//so as to ensure minimal change to the background
@function ada_swap_or_comply($background, $text, $alt_text) {
  $text_contrast: ada_contrast($background, $text);
  $alt_contrast: ada_contrast($background, $alt_text);

  @if ($text_contrast < 4.5) {
    @if ($alt_contrast < 4.5) {
      @if ($text_contrast >= $alt_contrast) {
        $more_contrasty: $text;
      } @else {
        $more_contrasty: $alt_text;
      }

      @return ada_comply_both($background, $more_contrasty);
    } @else {
      @return ("text_color": $alt_text, "bg_color": $background);
    }
  } @else {
    @return ("text_color": $text, "bg_color": $background);
  }
}
// takes a text and a bg color, darkening the darker and
// lightening the lighter until their contrast complies with the given ADA
// compliance level
// in the event the lighter reaches white or darker reaches black and contrast
// is still insufficient, returns false so you can add a manual fallback
//
@function ada_comply_both($text_color, $bg_color, $level: "AA") {
  @if ($ada_colors == false) {
    @return ("text_color": $text_color, "bg_color": $bg_color);
  }

  $text_lum: luminance($text_color);
  $bg_lum: luminance($bg_color);

  //make sure they're not the same or they'll both lighten to white in the recursion
  @if ($text_lum == $bg_lum) {
    //if they're both black, then lighten the text, else darken the bg
    @if ($bg_lum == 0) {
      $text_color: lighten($text_color, 0.25%);
    } @else {
      $bg_color: darken($bg_color, 0.25%);
    }
  }

  $contrast: ada_contrast($text_color, $bg_color);
  $ratio: get_ada_ratio($level);

  //@debug "contrast: #{$contrast}";
  //@debug "ratio: #{$ratio}";

  @if ($contrast < $ratio) {
    $contrast_diff: $ratio - $contrast;

    $step: $max_contrast_step * (($contrast_diff / $ratio) / 2);

    @if ($step < 1%) {
      $step: 1%;
    }

    //@debug $step;

    @return ada_comply_both(
      increase_contrast($text_color, $bg_color, $step),
      increase_contrast($bg_color, $text_color, $step),
      $level
    );
  } @else {
    @return ("text_color": $text_color, "bg_color": $bg_color);
  }
}

//  Given a string AA or AAA returns a float representing a passing contrast ratio
@function get_ada_ratio($level) {
  $ratio: 4.5;

  @if ($level == "AA") {
    $ratio: 4.5;
  } @else {
    $ratio: 7;
  }

  @return $ratio;
}

//  Takes a text colour, bg colour and an ADA level and
//  lightens or darkens the text colour til it complies or
//  reaches white or black
@function ada_comply($text_color, $bg_color, $level: "AA") {
  @if ($ada_colors == false) {
    @return $text_color;
  }
  $ratio: get_ada_ratio($level);

  $contrast: ada_contrast($text_color, $bg_color);
  $text_lum: luminance($text_color);
  $bg_lum: luminance($bg_color);

  @if ($contrast < $ratio) {
    $contrast_diff: $ratio - $contrast;

    $step: $max_contrast_step * (($contrast_diff / $ratio) / 2);

    @if ($step < 1%) {
      $step: 1%;
    }

    //if we're at pure black or pure white and contrast is still too low, flip the colors and try the other.
    @if ($text_lum <= 0.0001 or ($text_lum <= 1.0001 and $text_lum >= 1)) {
      @debug "!!! WARNING !!!: " + $text_color + "(" + $text_lum +
        ") can't be contrasted to " + $bg_color + " any more";

      @return false;
    }

    //@debug "Text: " + $text_color + " (" + $text_lum + ")";
    //@debug "BG: " + $bg_color;
    //@debug $step;

    @return ada_comply(
      increase_contrast($text_color, $bg_color, $step),
      $bg_color
    );
  } @else {
    @return $text_color;
  }
}

//if you need a button to not have button styling
//you should have a really good reason for adding the pointer.
//generally buttons shouldn't have pointers.
@mixin no-button-style(
  $add_pointer: false,
  $background: transparent,
  $border: none,
  $font_size: 1em,
  $border_radius: 0
) {
  background: $background;
  border: $border;
  font-size: $font_size;
  border-radius: $border_radius;

  @if ($add_pointer == true) {
    cursor: pointer;
  }
}

/**
  Takes no-button-style a step further for creating
  buttons that look like regular text for interactive
  elements with very custom styles
**/
@mixin plain-text-button {
  @include no-button-style;
  //width: 100%;
  text-align: left;
  color: inherit;
  font-weight: inherit;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}
@function cdn_path($prod, $server) {
  @return "//p" + $prod + "cdnstatic.sharpschool.com/UserFiles/Servers/Server_" +
    $server;
}

@function server_path($subdomain, $prod, $server) {
  @return "//" + $subdomain + ".ss" + $prod +
    ".sharpschool.com/UserFiles/Servers/Server_" + $server;
}

//create a pure css tooltip
@mixin tool-tip(
  $bg: #333,
  $color: #fff,
  $border_radius: 3px,
  $padding: 0.2em 0.5em,
  $shadow: true,
  $font_size: 0.6em,
  $speed: 0.2s,
  $triangle_direction: "down"
) {
  font-size: $font_size;
  background: $bg;
  color: $color;
  padding: $padding;
  border-radius: $border_radius;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none !important;
  //width: 15em;
  text-align: center;
  z-index: 99999;
  visibility: 0;
  opacity: 0;
  pointer-events: none;

  @if ($shadow) {
    box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.75);
  }

  @include animated($speed: $speed);

  /*&:after
    {
      display: block;
      content: '';
      width: 1em;
      height: 1em;
      background-color: $bg;
      position: absolute;
      bottom: -.5em;
      left: 50%;
      transform: translatex(-50%) rotate(45deg);
      }*/
  &:after {
    display: block;
    content: "";
    position: absolute;
    z-index: 2;

    @if ($triangle_direction == "down") {
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
    } @else if($triangle_direction == "up") {
      left: 50%;
      transform: translate(-50%, -50%);
      top: 0;
    }
    @if ($triangle_direction == "right") {
      left: 100%;
      transform: translateY(-50%);
      top: 50%;
    } @else if($triangle_direction == "left") {
      left: 0;
      transform: translate(-50%, -50%);
      top: 50%;
    }

    @include triangle($triangle_direction, $bg, $size: 1.5em);
    z-index: 2;
  }
}
//use on the parent element of a tooltip to add the hover functionality
@mixin show-tool-tip($selector: ".tool-tip", $end: -140%, $direction: y) {
  position: relative;

  &:hover #{$selector} {
    opacity: 1;

    @if ($direction == y) {
      transform: translate(-50%, $end);
    } @else if($direction == x) {
      transform: translate($end, -50%);
    }
  }
}

//makes a triangle using the transparent border trick
@mixin triangle($direction, $color, $size: 1em) {
  width: 0;
  height: 0;

  $size: $size / 2;

  @if ($direction == up) {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  } @else if($direction == right) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if($direction == down) {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  } @else if($direction == left) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  } @else {
    @debug "triangle() $direction must be one of up,right,down,left";
  }
}

@mixin circle($size) {
  width: $size;
  height: $size;
  border-radius: $size / 2;
}

@mixin flip-on-hover($size, $easing: $ease-out-back, $direction: x) {
  width: $size;
  height: $size;
  position: relative;

  > * {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    @include animated($easing: $easing);

    &:first-child {
      opacity: 1;
    }

    &:last-child {
      @if ($direction == x) {
        transform: scaleX(0);
      } @else {
        transform: scaleY(0);
      }

      opacity: 0;
    }
  }

  &:hover > * {
    &:first-child {
      @if ($direction == x) {
        transform: scaleX(-1);
      } @else {
        transform: scaleY(-1);
      }
      opacity: 0;
    }
    &:last-child {
      @if ($direction == x) {
        transform: scaleX(1);
      } @else {
        transform: scaleY(1);
      }
      opacity: 1;
    }
  }
}

@mixin slick_arrow(
  $prev_char: $fa-var-angle-left,
  $next_char: $fa-var-angle-right
) {
  &.slick-prev {
    @include iconify($prev_char);
  }
  &.slick-next {
    @include iconify($next_char);
  }
}

@function is-em($value) {
  @return unit($value) == "em" or unit($value) == "rem";
}

//don't use EMS. Because the dots will look like pills in iOS safari
@mixin slick_dot($size) {
  @if (is-em($size)) {
    @debug "Don't use ems or rems with slick_dot";
  }

  @include no-button-style;
  @include circle($size);
  font-size: 0em;
  text-indent: -999em;
  line-height: 0;
}

//Adds the offsets for all the breakpoints of the adminbar
@mixin adminbar_offset($property, $offset: 0px, $include_initial: true) {
  @if ($include_initial == true) {
    #{$property}: calc(50px + #{$offset});
  }

  @media (max-width: map-get($adminbar_break1,"width")) {
    #{$property}: calc(#{map-get($adminbar_break1, "height")} + #{$offset});
  }

  @media (max-width: map-get($adminbar_break2,"width")) {
    #{$property}: calc(#{map-get($adminbar_break2, "height")} + #{$offset});
  }

  @media (max-width: map-get($adminbar_break3,"width")) {
    #{$property}: calc(#{map-get($adminbar_break3, "height")} + #{$offset});
  }

  @media (max-width: map-get($adminbar_break4,"width")) {
    #{$property}: calc(#{map-get($adminbar_break4, "height")} + #{$offset});
  }
}

@mixin absolute_mobile_adminbar() {
  @media (max-width: $mobile_break) {
    #CMS_admin_ToolBar {
      position: absolute;
    }
  }
}

@mixin hide_mobile_adminbar() {
  @media (max-width: 1024px) {
    #CMS_admin_ToolBar,
    #placeholder-CMS_admin_ToolBar {
      display: none !important;
    }
  }
}

@mixin styled_table(
  $header_bg,
  $header_color,
  $odd_row_bg: transparent,
  $even_row_bg: transparent
) {
  width: 100%;

  td,
  th {
    padding: 0.2em 0.5em;
  }

  th {
    color: $header_color;
    background-color: $header_bg;
    text-align: left;
    font-weight: bold;
  }

  tbody tr:nth-child(odd) {
    background-color: $odd_row_bg;
  }

  tbody tr:nth-child(even) {
    background-color: $even_row_bg;
  }
}

@mixin exterior_shadow(
  $offset_side: top,
  $offset_distance: 0,
  $height: 5px,
  $start_color: rgba(0, 0, 0, 0.2),
  $end_color: rgba(255, 255, 255, 0),
  $shadow_direction: top,
  $shadow_z-index: 2,
  $element: before
) {
  position: relative;

  &::#{$element} {
    display: block;
    content: "";
    height: $height;
    background: linear-gradient(
      to #{$shadow_direction},
      #{$start_color} 0%,
      #{$end_color} 100%
    );
    position: absolute;
    #{$offset_side}: #{$offset_distance};
    left: 0;
    width: 100%;
    z-index: $shadow_z-index;
  }
}

@mixin inline-search() {
  display: flex;

  input {
    background-color: transparent;
    flex-grow: 1;
    border: none;
    padding: 0;
  }

  button {
    @include no-button-style();
    background-color: transparent;
    flex-grow: 0;
  }
}

@mixin default_link_color($color, $hover: null) {
  @if ($hover == null) {
    $hover: $color;
  }

  &,
  &:link,
  &:visited {
    color: $color;
  }

  &:hover,
  &:active,
  &:focus {
    color: $hover;
  }
}

@mixin icon-button($icon, $color, $hover_color: null, $button_style: false) {
  @if ($button_style == false) {
    @include no-button-style();
  }

  overflow: hidden;
  text-indent: -999em;

  &:before {
    float: left;
    text-indent: 0;
    @include font-awesome($icon);
    color: $color;
  }

  @if ($hover_color != null) {
    &:before {
      @include animated();
    }

    &:hover:before {
      color: $hover_color;
    }
  }
}

//  adds a box shadow the dimensions of a default browser focus ring.
//  Default color is the default focus ring blue
@mixin add-focus-ring($color: $focus_color) {
  box-shadow: 0px 0px 4px 2px $color;
  border-color: $focus_color;
}

//converts photoshop kerning value to ems
@mixin ps-kerning($ps_val) {
  letter-spacing: #{$ps_val / 1000}em;
}

//creates a border on the bottom of an element that doesn't span
//the full width of the element. Ben does this a lot in his designs
@mixin ben-border(
  $color,
  $border_width: 10%,
  $border_height: 0.227em,
  $spacing: 0.8em,
  $left_offset: 0,
  $side: after,
  $center: false
) {
  padding-bottom: $spacing;
  text-transform: none;

  &::#{$side} {
    content: "";
    height: $border_height;
    width: $border_width;
    background-color: $color;
    display: block;

    @if ($center == true) {
      transform: translate(-50%, #{$spacing});
      margin-left: 50%;
    } @else {
      transform: translateY(#{$spacing});
      margin-left: $left_offset;
    }
  }
}

//-------------------------------------------------------------------------------------
// Angled Edges v2.0.0 (https://github.com/josephfusco/angled-edges)
// Copyright 2017 Joseph Fusco
// Licensed under MIT (https://github.com/josephfusco/angled-edges/blob/master/LICENSE)
//-------------------------------------------------------------------------------------

/// Replace `$search` with `$replace` in `$string`.
///
/// @author Hugo Giraudel
/// @link   http://www.sassmeister.com/gist/1b4f2da5527830088e4d
///
/// @param  {String}  $string - Initial string
/// @param  {String}  $search - Substring to replace
/// @param  {String}  $replace ('') - New value
/// @return {String}  Updated string
///
@function ae-str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      ae-str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

/// Encode SVG to use as background.
///
/// @param  {String}  $string
/// @return {String}  Encoded svg data
///
@function ae-svg-encode($string) {
  $result: ae-str-replace(
    $string,
    "<svg",
    '<svg xmlns="http://www.w3.org/2000/svg"'
  );
  $result: ae-str-replace($result, "%", "%25");
  $result: ae-str-replace($result, '"', "'");
  $result: ae-str-replace($result, "<", "%3C");
  $result: ae-str-replace($result, ">", "%3E");

  @return "data:image/svg+xml," + $result;
}

/// Outputs pseudo content for main mixin.
///
/// @author Joseph Fusco
///
/// @param  {String}  $location
/// @param  {Number}  $height
/// @output psuedo content
///
@mixin ae-pseudo($wedge, $height, $width) {
  background-image: url($wedge);
  background-position: center center;
  background-repeat: no-repeat;

  // full width wedge - needed as Firefox ignores preserveAspectRatio="none" in this case
  @if ($width == null) {
    background-size: 100% 100%;
  }

  content: "";
  height: $height * 1px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
}

/// Attatches an svg wedge shape to an element.
///
/// @author Joseph Fusco
///
/// @param  {String}  $location   - 'inside top', 'outside top', 'inside bottom', 'outside bottom'
/// @param  {String}  $hypotenuse - 'upper left', 'upper right', 'lower left', 'lower right'
/// @param  {Color}   $fill
/// @param  {Number}  $height
/// @param  {Number}  $width
/// @output '::before' and/or '::after' with svg background image
///
@mixin angled-edge($location, $hypotenuse, $fill, $height: 100, $width: null) {
  position: relative;

  $points: (
    "upper left": "0,#{$height} #{$width},#{$height} #{$width},0",
    "upper right": "0,#{$height} #{$width},#{$height} 0,0",
    "lower left": "0,0 #{$width},#{$height} #{$width},0",
    "lower right": "0,0 #{$width},0 0,#{$height}",
  );

  // full width wedge
  @if ($width == null) {
    $points: (
      "upper left": "0,#{$height} 100,#{$height} 100,0",
      "upper right": "0,#{$height} 100,#{$height} 0,0",
      "lower left": "0,0 100,#{$height} 100,0",
      "lower right": "0,0 100,0 0,#{$height}",
    );
  }

  // ensure $fill color is using rgb()
  $fill-rgb: "rgb(" + round(red($fill)) + "," + round(green($fill)) + "," +
    round(blue($fill)) + ")";

  // capture alpha component of $fill to use with fill-opacity
  $fill-alpha: alpha($fill);

  $wedge: '<svg width="#{$width}" height="#{$height}" fill="#{$fill-rgb}" fill-opacity="#{$fill-alpha}"><polygon points="#{map-get($points, $hypotenuse)}"></polygon></svg>';

  // full width wedge
  @if ($width == null) {
    $wedge: '<svg preserveAspectRatio="none" viewBox="0 0 100 #{$height}" fill="#{$fill-rgb}" fill-opacity="#{$fill-alpha}"><polygon points="#{map-get($points, $hypotenuse)}"></polygon></svg>';
  }

  $encoded-wedge: ae-svg-encode($wedge);

  @if ($location == "inside top") {
    &::before {
      @include ae-pseudo($encoded-wedge, $height, $width);
      top: 0;
    }
  } @else if ($location == "outside top") {
    &::before {
      @include ae-pseudo($encoded-wedge, $height, $width);
      top: -$height * 1px;
    }
  } @else if ($location == "inside bottom") {
    &::after {
      @include ae-pseudo($encoded-wedge, $height, $width);
      bottom: 0;
    }
  } @else if ($location == "outside bottom") {
    &::after {
      @include ae-pseudo($encoded-wedge, $height, $width);
      bottom: -$height * 1px;
    }
  } @else {
    @error 'Invalid argument for $location - must use: `inside top`, `outside top`, `inside bottom`, `outside bottom`';
  }
  @if (map-has-key($points, $hypotenuse) == false) {
    @error 'Invalid argument for $hypotenuse - must use: `upper left`, `upper right`, `lower left`, `lower right`';
  }
}

//adds a background color to individual lines of an element
// !IMPORTANT! Only works if the element is display inline
@mixin line-background($color) {
  background-color: $color;
  box-shadow: 0.5em 0 0 $color, -0.5em 0 0 $color;
  display: inline;
}

@mixin ie_grid_positions($columns, $rows) {
  $child: 0;

  @for $r from 1 through $rows {
    @for $c from 1 through $columns {
      $child: $child + 1;

      &:nth-child(#{$child}) {
        -ms-grid-column: $c;
        -ms-grid-row: $r;
      }
    }
  }
}

//modals are busted on some servers / presence versions, but not other
//only use this if modals are broken
@mixin fix-presence-modal() {
  .CMS_PF_dialog .ui-dialog-content {
    overflow: hidden !important;
  }
}

//for when you want to set less than 4 of the sides for a property without having to repeat the vals or do a none override

@mixin border($sides, $val) {
  @include split-property(border, $sides, $val);
}

@mixin margin($sides, $val) {
  @include split-property(margin, $sides, $val);
}

@mixin padding($sides, $val) {
  @include split-property(padding, $sides, $val);
}

//  @param $prop {String} the property base we're setting, see short hand mixins above for usage
//  @param $sides {List|'horizontal'|'vertical} a list of the sides to set, or a keyword- horizontal = (left,right), vertical = (top,bottom)
//  @param $vals {CSS Values} the shorthand values to set each side to
@mixin split-property($prop, $sides, $vals) {
  @if ($sides == horizontal) {
    $sides: (left, right);
  }

  @if ($sides == vertical) {
    $sides: (top, bottom);
  }

  @each $side in $sides {
    #{$prop}-#{$side}: $vals;
  }
}

//
//  a mixin for the padding-bottom aspect ratio hack
//  html structure should be

//  element
//    element
//      element

//  where the outter most element is what has a fixed width
//  the second element is what includes the mixin
//  the inner element is targetted by the container_selector
//  @see https://codepen.io/raymond8505/pen/BqgxxX
///
@mixin maintain-aspect-ratio($width, $height, $container_selector) {
  width: 100%;
  padding-bottom: #{($height / $width) * 100%};
  position: relative;

  #{$container_selector} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@mixin style-mmenu($bg_color, $link_color, $stroke_color: $link_color) {
  //Handles some basic repetive stuff for styling the mmenu.

  .mm-menu {
    background-color: transparent;

    .mm-list {
      background-color: $bg_color;
    }

    .mm-list li a {
      @include no-decoration($link_color);
    }

    .mm-list > li > a.mm-subopen:after {
      border-color: $link_color;
    }

    .mm-list > li > a.mm-subopen:before,
    .mm-list > li:after {
      border-color: $stroke_color;
    }
  }
}

//forces open a mega menu sub-nav so you dont have to
//constantly be hovering it while working
@mixin mm-force-open {
  .sub-nav {
    pointer-events: auto;

    .sub-nav-group {
      transform: translateY(0);
    }
  }
}

//if you're maun
@mixin reinstate-new-calendar-styles {
  .PO-calendar .CAL-box .CAL-daysOfWeek ul li {
    display: table-cell;
  }

  .PO-calendar .CAL-box .CAL-topControls > ul > li,
  .PO-calendar .CAL-search > ul > li {
    display: inline-block;
  }

  .PO-calendar .CAL-dropdown li {
    display: block;
    //position: absolute;
  }

  .PO-calendar
    .CAL-box
    .CAL-topControls
    .CAL-DD-monthGrid
    li.CAL-DD-year-button {
    position: absolute;
  }

  .CAL-dropdown.CAL-DD-monthSelect.js-nav-system__subnav > ul > li {
    position: static;
  }
}
//overrides calendar colours.
//Only use this if the client specifically asks that all calendars always have
//the same colours
@mixin override_calendar_colors(
  $main_color,
  $label_color: #fff,
  $today_color: null,
  $event_background: null,
  $event_color: null,
  $selected_color: null,
  $month_grid_color: null,
  $ui_color: null
) {
  @if ($event_background == null) {
    $event_background: $main_color;
  }

  @if ($selected_color == null) {
    $selected_color: $label_color;
  }

  @if ($today_color == null) {
    $today_color: lighten($main_color, 10%);
  }

  @if ($event_color == null) {
    $event_color: $label_color;
  }

  @if ($month_grid_color == null) {
    $month_grid_color: $label_color;
  }

  @if ($ui_color == null) {
    $ui_color: $label_color;
  }

  //#template and #theme are required to override the on page style since it's !important and pointed at the auto generated
  //portlet ID
  //your theme should have both those IDs
  html body #template #theme div.ptl_page .PO-contentArea .CAL-wrapper {
    .CAL-box {
      background: $main_color !important;
    }
    .CAL-EV-Item a,
    .CAL-topControls .CAL-DD-monthGrid li.CAL-DD-monthButton a.CAL-button {
      background: $event_background !important;
      color: $month_grid_color !important;
    }

    .CAL-V-today .CAL-V-MV-selectDay {
      border-color: $today_color;
      background: rgba($today_color, 0.5);
    }

    .CAL-box .CAL-topControls .CAL-monthSelector,
    .CAL-box .CAL-daysOfWeek ul li,
    .CAL-timeAlert {
      color: $ui_color !important;
    }

    .CAL-V-MV-selectDay.CAL-V-MV-SD-selected {
      border-color: $selected_color;
      background: rgba($selected_color, 0.5);
    }

    .CAL-EVENT-title {
      color: $label_color;
    }
  }
}

//easily scope stuff to target things in a small column
@mixin small_column {
  .medium-8,
  .medium-7,
  .medium-6,
  .medium-5,
  .medium-4,
  .medium-3,
  .medium-2,
  .medium-1,
  .ptl_col_1_3,
  .ptl_col_1_4 {
    @content;
  }
}
//if you have a static header, and / or the admin bar is present, anchors need to be offset to be accurate
//if you just want to offset for the admin bar call this without a distance
@mixin offset_anchors($distance: 0px, $include_admin_mobile: false) {
  a[name] {
    position: relative;
    top: $distance;
    display: block;
  }

  .has-adminbar a[name] {
    top: calc(#{$distance} - 50px);

    @if ($include_admin_mobile == true) {
      @media (max-width: 1033px) {
        top: calc(#{$distance} - 100px);
      }

      @media (max-width: 790px) {
        top: calc(#{$distance} - 140px);
      }

      @media (max-width: 720px) {
        top: calc(#{$distance} - 180px);
      }

      @media (max-width: 433px) {
        top: calc(#{$distance} - 220px);
      }
    }
  }
}

//indents the various levels of the default side nav
@mixin indent_side_nav($distance: 1em, $start_level: 2, $end_level: 5) {
  @for $level from $start_level through $end_level {
    .level#{$level} {
      padding-left: $distance * ($level - 1);
    }
  }
}

@mixin scrollbar-style(
  $width: 5px,
  $bar-color: black,
  $track-color: rgba(black, 0.25)
) {
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-thumb {
    background: $bar-color;
  }

  &::-webkit-scrollbar-track {
    background: $track-color;
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/**
*
* @param [$rotation-duration] default 2s : the time it takes to complete 1 rotation
*/
@mixin infinite-rotation($rotation-duration: 2s) {
  -webkit-animation: rotating $rotation-duration linear infinite;
  -moz-animation: rotating $rotation-duration linear infinite;
  -ms-animation: rotating $rotation-duration linear infinite;
  -o-animation: rotating $rotation-duration linear infinite;
  animation: rotating $rotation-duration linear infinite;
}
