$spinner_color: $color;

.Loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $gold;
}
.Loader__message {
  text-align: center;
  font-size: 3rem;
  margin-top: 1em;
}
.lds-dual-ring {
  display: none;
  //display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid $spinner_color;
  border-color: $spinner_color transparent $spinner_color transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Loader__logo,
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  max-width: 100%;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0) rotate(1.5deg);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0) rotate(-1.5deg);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0) rotate(1.5deg);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0) rotate(-1.5deg);
  }
}
