html,body
{
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.45;
}

html {
    scroll-behavior: smooth;
  }

h1,h2,h3,h4,h5,h6
{
    margin-top: 0;
    line-height: 1.2;
}

a {
  //text-decoration-skip: leading-spaces trailing-spaces;
}

//target content portlets
div[id*="_ContentPlaceHolder"]
{
    img[style*="float: left"]
    {
        margin: 0 1em .5em 0;
    }

    img[style*="float: right"]
    {
        margin: 0 0 .5em 1em;
    }
}

figure
{
    margin: 0;
}

img
{
    max-width: 100%;
}