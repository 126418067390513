.GameCardFreeSquare__settings-btn,
.GameCardFreeSquare__join-group,
.GameCartFreeSquare__help {
  @include no-button-style;
  color: $color;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: $gold;
  }

  //left: 0;
}
// .GameCardFreeSquare__settings-btn,
// .GameCardFreeSquare__re-rolls
// {
//   @include desktop-only
//   {

//     position: absolute;
//     bottom: 0;
//   }
// }
.GameCardFreeSquare__controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.GameCardFreeSquare__re-rolls {
  //right: 0;

  .fa {
    margin-right: 0.2em;
    opacity: 0.5;
    color: darken($gold, 15%);
  }
}
.GameCardFreeSquare__re-rolls-val {
  font-weight: bold;
}
.GameCardFreeSquare {
  position: relative;
  //padding-bottom: 1.5em;

  @include mobile-only {
    .GameCardFreeSquare__controls {
      font-size: 20px;
      justify-content: center;

      > * {
        margin: 0;
      }
    }
  }
}
