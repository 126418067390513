$color: #111;

$background: #efefef;

$background_light: #fff;

$accent: darkslateblue;

$green: green; //this could change eventually

$gold: #fbc201;

$red: red;

$dark_grey: #333;

$phone_width: 1000px;

$mobile_menu_toggle_width: 3em;

$roboto: "Open Sans", Roboto, Helvetica, SanFransisco, Arial, _sans;

$kanit: "Kanit", Roboto, Helvetica, sans-serif;
$arbutus: "Arbutus", Times, "Times new Roman", _serif;

@media (prefers-color-scheme: dark) {
  :root {
    --color: red;
  }
}
