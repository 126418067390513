.ComboCounter {
  @include animated($speed: 0.3s);
  position: fixed;
  right: 1em;
  top: 3em;
  padding: 0.5em;
  z-index: 99;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: $background_light;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  //width: 15.2rem;

  > * {
    margin-left: 1rem;
  }
}
.ComboCounter--closed {
  transform: translateX(100%);
  right: 2em;
}
.ComboCounter__shot-glass {
  clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
  background: rgba(black, 0.1);
  height: 50px;
  width: 50px;
  position: relative;
}
.ComboCounter__shot-volume {
  background: linear-gradient(0deg, $accent 70%, rgba(0, 0, 0, 0) 100%);
  height: 33%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  @include animated($speed: 0.1s, $easing: linear);

  .ComboCounter--full & {
    background: $accent;
  }
}
.ComboCounter__add-drink-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: $green;
  font-size: 2rem;
  font-weight: bold;
  padding: 0;
  border: none;
  outline: none;

  &:hover {
    background: darken($gold, 2%);
    color: #fff;
  }
}
.ComboCounter__remove-drink-btn,
.ComboCounter__clear-drinks-btn {
  @include no-button-style;
  color: $color;
  background: transparent;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 1.25em;
  padding: 0;
  margin: 0 0.15em;

  &:hover {
    background: transparent;
    color: darken($color, 2%);
  }
}
.ComboCounter__button-shell {
  text-align: center;
}
.ComboCounter__count {
  font-size: 3.5rem;
  font-weight: bold;

  line-height: 1;
  @include animated;
  transform: translateX(100%);
  opacity: 0;

  .ComboCounter--animated & {
    transform: translateX(0);
    opacity: 1;
  }

  .ComboCounter--full & {
    animation: blink 0.3s linear 3;
    animation-delay: 0.35s;
  }
}
.ComboCounter--empty .ComboCounter__count {
  opacity: 0;
}
.ComboCounter__toggle-btn--open {
  opacity: 0;
  pointer-events: none;
  margin-left: 0;

  .ComboCounter--closed & {
    opacity: 1;
    pointer-events: all;
  }
}
.ComboCounter__toggle-btn {
  @include no-button-style;
  font-size: 1rem;
  padding: 0;
  color: $color;
  //align-self: center;
}
