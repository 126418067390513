.GameCard {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: min-content auto;
  gap: 0.5em;
  min-height: 100vh;
  background: $background_light;
  margin: auto;

  @include mobile-only {
    @media (orientation: landscape) {
      min-width: 100vw;
    }
  }
}
.rule-box,
.header-box {
  padding: 0.5em;
}
.rule-box {
  background: $background;
  position: relative;
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @include animated;
  border-radius: 0.75rem;
}
.rule-box--free {
  background-color: $background_light;

  h1 {
    font-size: 1.4rem;
    font-family: $arbutus;
  }
  h2 {
    font-size: 1.4rem;

    @include desktop-only {
      text-shadow: 0 0 2px black;
      color: $gold;
    }

    letter-spacing: 2px;
    padding: 0.2em;
    text-transform: uppercase;
  }
  h1,
  h2 {
    margin: 0;
    font-weight: $fw_medium;
  }
}
.rule-box__drinks {
  font-size: 1.5em;
  font-weight: $fw_medium;
  padding: 0.5em;

  @include mobile-only {
    position: absolute;
    left: 0;
    bottom: 2px;
    color: $gold;
    font-weight: $fw_medium;
    font-size: 1.6rem;
  }
}
.rule-box__game-title {
  font-size: 0.75em;

  @include mobile-only {
    display: none;
  }
}

.rule-box:not(.rule-box--free) .rule-box__rule {
  font-size: 1.2em;

  @include mobile-only {
    font-size: 1em;
    letter-spacing: 1px;
  }
}
.header-box {
  font-size: 2rem;
  line-height: 1;
  font-weight: $fw_black;
  background-color: $gold;
  color: $color;
  //text-shadow: 0 0 4px white;
  box-shadow: 0 1px 2px rgba(black, 0.4);
  z-index: 26;
  font-family: $arbutus;
}
.rule-box--highlighted:not(.rule-box--free) {
  background-color: $gold;
  box-shadow: 0 0 4px rgba(black, 0.3);

  &,
  .rule-box__drinks {
    //color: $background_light;
  }
}
.rule-box--game-rule:not(.rule-box--highlighted) .rule-box__game-title {
  font-weight: $fw_medium;
  color: $red;
}
.App--GameCard {
  overflow: auto;
}
.App--GameCard .App--mobile {
  padding-top: 0;
}
.App--GameCard {
  //font-size: 0.7rem;

  .header-box {
    position: sticky;
    top: 0;
  }
}
@include mobile-only {
  html,
  body {
    font-size: 10px;
  }
  .rule-box {
    padding: 1.5rem;
  }

  .GameCardFreeSquare {
    h1,
    h2 {
      font-size: 1em;
    }
  }

  // @media (orientation: portrait) {
  //   .App--GameCard {
  //     font-size: 0.45em;
  //   }
  // }
}
.rule-box__re-roll,
.rule-box__mug {
  padding: 0;
  @include no-button-style;
  color: $color;
  opacity: 0.5;
  position: absolute;
  bottom: 9px;
  right: 9px;

  &:hover {
    color: $gold;
    opacity: 1;
  }
}
@include mobile-only {
  .rule-box__re-roll {
    font-size: 20px;
  }
}
.RuleSquare {
  //padding: 0 1em 1em;
}
.rule-box__mug {
  display: none;
  opacity: 1;
  transform: rotate(-10deg);
}
.rule-box--highlighted {
  .rule-box__re-roll {
    display: none;
  }
  .rule-box__mug {
    display: block;
  }
}
