.GameList
{
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    @media (max-width: 1620px) and (min-width: $phone_width + 1)
    {
        font-size: .85em;
    }

    @media (max-width: 1350px) and (min-width: $phone_width + 1)
    {
        font-size: .75em;
    }
}
.GameList__items
{
    margin-left: 0;
    padding-left: 0;
    flex-grow: 1;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: rgba($background,.5); 
    }
    
    &::-webkit-scrollbar-track {
        background: transparent; 
    }
}
.GameList__filter-field
{
    background: rgba($background_light,.05);
    border: none;
    border-bottom: 1px solid $background_light;
    color: $background_light;
    padding: .3em;
}