//make portlet shells overflow visible, but still not collapse on floated
html:not(.designMode) .ptl_portlet_vertical
{
	overflow: visible;
	@include clear-fix();
}

//force colours for the drag drop wrapper
html body div#CMS_DragDropWrapper
{
	.DDW_portletSelection a
	{
	    color: #a6a8a9;

	    &.active,
	    &:hover
	    {
	    	color: #000;
	    }
	}
}
//make images added to content areas responsive
html body div[id$="_divContent"] img,
html body #news_content_body img
{
	max-width: 100%;
}

//give floated images in content portlets a 1em margin on the bottom and their opposite side.
div[id*="ContentPlaceHolder"] img,
div[id*="ContentPlaceHolder"] figure
{
    &[style*="float: left"]
    {
        margin: 0 1.5em 1em 0;
    }

    &[style*="float: right"]
    {
        margin: 0 0 1em 1.5em;
    }
}

//give legacy layout columns padding
*[class*="ptl_col_"]:not(.ptl_col_1)
{
	padding: 0 $gutter / 2;

	&:last-child
	{
		padding-right: 0;
	}
	&:first-child
	{
		padding-left: 0;
	}
}
//a fix for combatibility with the Multifield Portlet's drag and drop interface
.ui-sortable-placeholder.mfp-item
{
	display: inline-block;
}

.portlet-title
{
	color: #fff !important;
}

//removes the vertical scrollbar from the "Change page status from Hidden to Publish?" modal when
//creating a page
.modalPopup
{
	min-height: 163px;
}

#sidebar .portletBox-title
{
	width: 100% !important;
}

#sidebar .ptl_col_1
{
	padding-right: 0;
}

.bannerContainer
{
	&, .nivoSlider
	{
		max-width: none !important;
		max-height: none !important;
	}
}

//the sorting arrows overlap the column label on smaller labelled columns like "status"
#rfp-table th
{
	padding-right: 20px;
}

//get rid of the declared font families for the admin_menu
.admin_menu, a.admin_menu, a.admin_menu:link, a.admin_menu:visited, a.admin_menu:active, a.admin_menu:hover
{
	font-size: inherit;
	color: inherit;
	font-family: inherit;
	text-decoration: inherit;
}

//override default news image styling
html body #news_content_body #featured_image,
html body #news_content_body
{
	padding: 0;
}
#featured_image
{
	margin: 0 1em 1em 0;
}
@media (max-width: $small_break)
{
	#news_content_body #featured_image
	{
		float: none;
		margin: 0 auto 1em auto;
		display: block;
		width: 100%;
		
	}
}
.exlink-modal-dialog .CMS_modal-content a
{
	height: auto;
}
*[id$="_divChangToPublish"] .tbl_row_data > br
{
	display: none;
}
#news_content_title
{
	padding-top: 0;
}
//hide the admin bar when the mobile nav is opened
.mm-opened #CMS_admin_ToolBar
{
	display: none;
}
div.ptl_page
{
	overflow: visible;
}

//override default font sizing for the <credits /> tag
html body *[id$="_lblCompany"]
{
	font-size: inherit;
}

//common.css sets the font family to Arial. This forces it to inherit so tabs have the same font as the rest of the site.
.PO-wrapper
{
	font-family: inherit !important;
}

.ui-dialog .ui-dialog-content
{
	z-index: 9 !important;
}
@media (max-width: $mobile_break)
{
	#local-label
	{
		display: none;
	}
}
body dt
{
	color: inherit;
}
.inlineedit_overlay
{
	z-index: 100 !important;
}
html body form div[id*="PO-documentContainer"]
{
	.PO-settingMenuBar li,
	.PO-guestButtons li,
	.PO-pageButton li,
	.PO-listHeader li
	{
		list-style: none !important;
		display: inline !important;
	}
	.PO-settingsBar > *
	{
		display: table-cell !important;
	}
	ul.documentList li
	{
		display: block !important;
	}
	.PO-pageButton li
	{
		padding: 0 !important;
	}
}
.portlet_instance[style*="z-index: 10001"]
{
	z-index: 899 !important;
}
#ui-datepicker-div
{
	z-index: 130001 !important;
}
.RadWindow.RadWindow_Silk.rwNormalWindow.rwTransparentWindow
{
	z-index: 200000 !important;
}
//the 301 redirects table can get funky and have a horizontal scroll because of the list of long URLs. This forces them to break
//and maintain a good layout
table[id$="gvRedirectUrls"] { word-break: break-all; }

//this makes it so you can select things when they're inline with Drag and Drop Wrapper tabs when
//the portlets bar is open
.DDW_tabs,
.DDW_tabs > ul,
.CMS_DragDropWrapper
{
	pointer-events: none;
}
.DDW_tabs > ul > li,
.CMS_DragDropWrapper > div:not(.DDW_tabs)
{
	pointer-events: all;
}
.Silk.reDropDownBody
{
	z-index: 999999 !important;
}
//if the body is position absolute | relative and smaller than an open modal, 
//the modal's positioning is thrown off and its content may be cut off. This solves that.
.CMS_PF_dialog_open body
{
	min-height: 100vh;
}