.GridPicker {
  display: grid;
  gap: 2px;
  grid-template-rows: repeat(5, 40px);
  grid-template-columns: repeat(5, 40px);

  @include mobile-only {
    @media (orientation: portrait) {
      grid-template-rows: repeat(5, 15.5vw);
      grid-template-columns: repeat(5, 15.5vw);
    }
  }
  button {
    width: 100%;
    height: 100%;
    display: block;
  }
}
button.GridPicker__btn {
  background-color: rgba($color, 0.1);
  border: none;
}
button.GridPicker__btn--highlight {
  background-color: $gold;
}
