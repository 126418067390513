*:focus:not(:focus-visible)
{
    outline: none;
}
.pull-right
{
	float: right;
}
.pull-left
{
	float: left;
}
//,used for screen reader stuff
.off-screen,
.sr-only
{
	contain: paint;
    opacity: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 0;
    width: 0;
    visibility: hidden;
    font-size: 0;
    clip: rect(0px,0px,0px,0px);
    border: none;
    position: fixed;
    top: -200vh;
    left: -200vw;
}
.servermetadata
{
    display: none;
}
.inline-list
{
	&,li
	{
		padding: 0;
		margin: 0;
		display: inline-block;
		line-height: 1em;
	}
}

.object-fit
{
    object-fit: cover;
    object-position: center center;
}
span.object-fit
{
    background-position-x: center;
    background-position-y: center;
}

.lazyloading,
.lazyload
{
    object-fit: scale-down;
    object-position: center center;
}
//hide images without a src
img:not(img[src])
{
    opacity: 0;
}

//the skip to content button- the first link on the page that can receive tab focus to skip down to the content
//this will hide it in the top left of the browser and slide it down when focused.
#skip-to-content,
#skip,
.skip-to-content
{
    font-size: 2em;
    padding: .2em .4em;
    background-color: #fff;
    color: #000;
    position: fixed;
    z-index: 999999999999;
    top: 0px;
    left: .8em;
    display: inline-block;
    @include animated();
    transform: translateY(-100%);
    
    &:focus
    {
        transform: translateY(0);
    }
}

._Telerik_IE9
{
    #skip-to-content,
    .skip-to-content
    {
        display: none;

        &:focus
        {
            display: block;
        }
    }
}

//when lazy loading images, add the ll class to have them fade in when loaded instead of popping in
.ll
{
    @include animated();
    //opacity: 0;

    &.lazyloaded
    {
        //opacity: 1;
    }
}

.lazyloaded
{
    animation: fadeIn 0.4s 1;
}

@keyframes fadeIn
{
    0%
    {
        opacity: 0;
    }
    100%
    {
        opacity: 1;
    }
}

.more-link
{
    &:hover,&:active,&:link,&:focus,&:visited
    {
        text-decoration: none;
    }
    
}
.more-link:after
{
    @include font-awesome($fa-var-chevron-right);
    margin-left: .2em;
    @include animated();
    display: inline-block;
}
.more-link:hover:after
{
    transform: translateX(.2em);
}
//often times ctas will need to be centered in their parent, while sibling content isn't
//other things may also need to happen beyond simple centering, so lets standardize the class name
//for wrappers of CTAs
.cta-shell
{
    text-align: center;
}

//sometimes a template will remove a space you need, 
//but won't compile if you insert &nbsp; so use a i,em or span class space to do the job
.space
{
    display: inline-block;
    width: .333em;
}

//if you have a collection of inline block items and need to break at a certain point, this'll do it.
//just add an element with this class at the position, and in your css in break point set display: inline-block !important
//on it
.br
{
    display: none !important;
    width: 100%;
    height: 0;
    opacity: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    line-height: 0;
    font-size: 0;
    border: none;
}

//overflowing-children is a class added to any portlet that has direct child elements wider than itself
//usually this is a portlet using tables for layout, some things look a little better when those tables
//are width: 100%
.overflowing-children > table
{
    width: 100%;
}

//helps style address info with hcard classes which often appear in the footer. The Render Templates remove the spaces and don't allow use of &nbsp;
.locality,
.region,
.postal-code
{
    display: inline-block;
}

.locality
{
    margin-right: .2em;

    &:after
    {
        content: ',';    
    }
    
}

//this is hacky, but Presence is garbage so...
.space-after
{
    margin-right: .3em;
}

.space-before
{
    margin-left: .3em;
}

//style the bug report fields
textarea#description
{
    width: 100%;
    height: 300px;
}
input#url
{
    width: 100%;
}

/* defines a padded block element with rounded corners */
.pill
{
    display: inline-block;
    padding: .5em 2em;
    border-radius: 1em;
    text-align: center;
}

.mfp-item img
{
    max-width: 100%;
}

@media (min-width: $mobile_break)
{
    .mobile-only
    {
        display: none !important;
    }
}

@media (max-width: $mobile_break - 1)
{
    .desktop-only
    {
        display: none !important;
    }
}

.field-and-button
{
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    input
    {
        flex-grow: 1;
        width: auto;
    }
}

.fa
{
    font-display: block;
}

.no-decoration
{
	&:hover,
	&:active,
	&:focus,
	&
	{
        text-decoration: none !important;
        border-bottom: none !important
	}
}

.ie, .edge, .safari
{
    .lazyload:not(.lazyloaded),
    .lazyloading
    {
        opacity: 0;
    }
}
.admin #sidebar
{
    display: none;
}
.admin #page
{
    width: 100%;
}
@media print
{
    .no-print
    {
        display: none !important;
    }
}
.fa:before,
.fa:after
{
    font-family: FontAwesome !important;
}

//make render template dunps more legible
child, rss, xmp {
    max-width: 100%;
    word-wrap: break-word;
}

//new modals dont play well with the framework for some reason
div.ui-front
{
    //z-index: $modal_zindex !important;
}