.SeassionManager__session-key
{
    margin-right: 1em;
    font-size: 1.3em;
    font-weight: bold;
}
.SessionManager__buttons
{
    display: flex;
    align-items: stretch;
}