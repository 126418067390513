.Index__header {
  background-color: $gold;
  padding: 1em;

  &,
  h1 {
    color: $color;
  }
  font-family: $arbutus;
  position: sticky;
  top: 0;

  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    span:first-child {
      text-transform: uppercase;
      font-size: 1.25em;
    }

    span {
      flex-shrink: 1;
    }

    span:not(:first-child) {
      color: white;
    }
    img {
      height: 1.5em;
      margin: 0 0.5em;
    }

    @include mobile-only {
      //font-size: 1.75em;

      @media (orientation: portrait) {
        font-size: 2em;

        img {
          //order: -1;
          height: 2em;
        }
        span:first-child {
          font-size: 1.55em;
        }
        span:not(:first-child) {
          font-size: 1.15em;
        }
      }
    }
  }
}
.Index__header {
  color: white;
}
body.Index--body {
  overflow: auto;
  .App {
    padding-top: 0;
  }
}
.Index__search {
  width: 100%;
  display: block;
  background: white;
  border: none;
  padding: 0.5em 0.8em;
  font-size: 1.5em;

  outline: none;
  font-family: $kanit;
}
.Index__results {
  background-color: white;
  list-style: none;
  display: grid;
  gap: 1vmin;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: min-content;

  @media (max-width: 820px) {
    grid-template-columns: repeat(2, 1fr);
  }
  margin: 0;
  padding: 1vmin;
}
