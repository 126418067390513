.Desktop
{
    display: flex;
    align-items: stretch;
    height: 100vh;
    overflow: hidden;

    .GameShell
    {
        width: 80%;
        margin-left: 1vw;
        //padding-left: 1vw;
        display: flex;
        flex-direction: column;
        justify-content: stretch;

        @media (max-width: $phone_width)
        {
            width: 100%;
        }

        .Game
        {
            flex-grow: 1;
            padding-left: 0;

            &::-webkit-scrollbar {
                width: 10px;
            }
            
            &::-webkit-scrollbar-thumb {
                background: rgba($color,.5); 
            }
            
            &::-webkit-scrollbar-track {
                background: transparent; 
            }
        }
        //border-left: 1px solid $color;
    }

    .Game,.Games
    {
        padding: 1vw;
        overflow-y: auto;
    }

    .Games
    {
        width: 20%;
        flex-shrink: 0;
        flex-grow: 0;
        @include padding((right,bottom),0);

        &, .Games__toggle-btn
        {
            
        }
        background: lighten($color,5%);
        color: $background;
        //overflow: hidden;

        a
        {
            @include default_link_color($background);
            text-decoration: none;
        }

        button
        {
            &,*
            {
                //background: transparent !important;
            }
        }

        .EditButton__icon,.DeleteButton__icon,.GameListItem__copy-btn .fa
        {
            color: darken($background,30%);
            @include animated;

            &:hover
            {
                color: $background;
            }
        }

        .GameList
        {
            //display: block;
            margin-right: .5em;
            //height: 300px;

            
        }

        .GameSelect,
        input
        {
            width: 100%;
            margin-bottom: .2em;
        }
    }

    .Games .Games__add-a-game
    {
        background: transparent;
        border-top-color: rgba($background,.2);
        padding-right: 0;
        margin-right: 1em;

        button
        {
            background-color: $background !important;
            color: $color;
        }
    }
}
.Desktop__alert-shell
{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: $phone_width)
{
    .Desktop > .Games
    {
        position: fixed;
        height: 100vh;
        min-width: 400px;
        top: 0;
        left: 0;
        z-index: 2;
    }
}
