.Modal {
  display: none;
  text-align: left;
  padding: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(black, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 999;
  pointer-events: none;

  &[open] {
    display: flex;
  }
}
.Modal .Modal__close {
  @include no-button-style;
  color: $color;
  padding: 0;
  box-shadow: none;
  float: right;
  font-size: 2em;
  font-variant: normal;
  color: $red;
}
.Modal__inner {
  pointer-events: all;
  max-width: calc(100% - 4em);
  max-height: calc(100% - 4em);
  background: $background;
  border: 2px solid $color;
  padding: 2em;
  padding-top: 0;
  width: 80%;

  @include mobile-only {
    width: 100%;
    max-width: none;
    max-height: 100vh;
  }

  box-shadow: 0 0 5vw 0 black;
  overflow: auto;
  color: $color;

  @include scrollbar-style($bar-color: $color, $track-color: transparent);
}
.Modal__header {
  position: sticky;
  top: 0;
  padding-bottom: 1em;
  padding-top: 2em;
  background-color: $background;
  z-index: 2;
}
.Modal__title {
  margin: 0;
}
.Modal--black-and-white {
  .Modal__inner {
    color: $color;
    background: $background;
    border-color: $color;

    @include scrollbar-style($bar-color: $color);
  }

  .Modal__close {
    color: $color;
  }
  .FieldSet {
    border-color: $color;

    legend {
      color: $color;
    }
  }
  .Modal__header {
    background: $background;
  }
}
