html body
{
    .w-25 { width: 25%; float: left; }
    .w-30 { width: 30%; float: left; }
    .w-33 { width: 33.33334%; float: left; }
    .w-40 { width: 40%; float: left; }
    .w-50 { width: 50%; float: left; }
    .w-60 { width: 60%; float: left; }
    .w-66 { width: 66.66667%; float: left; }
    .w-70 { width: 70%; float: left; }
    .w-75 { width: 75%; float: left; }

    @media screen and (max-width: 640px) {

     .w-66 {
      width: 100%;
      float: none;
    }

    .w-40 {
      width: 100%;
      float: none;
    }

    .w-60 {
      width: 100%;
      float: none;
    }

    .w-33 {
      width: 100%;
      margin-bottom: 15px;
    }

    .w-33-col-50 { width: 50%; float: left; }

    .w-33-col-50:first-child .inside-padding { padding-right: 10px; }
    .w-33-col-50:last-child .inside-padding { padding-left: 10px; }

    .w-25 { width: 100%; float: none; }
    .w-75 { width: 100%; float: none; }

    .w-50 { width: 100%; float: none; }

    .nivo-caption {
      display: none !important;
    }
  }


  @media screen and (max-width: 450px) {

   .w-33-col-50 { width: 100%; float: none; }

   .w-33-col-50:first-child .inside-padding { padding: 10px; }
   .w-33-col-50:last-child .inside-padding { padding: 10px; }

   .w-33 > .inside-padding, .w-66 > .inside-padding {
    padding: 0px 10px 0px 10px;
  }

  }

  @media screen and (max-width: 450px) {

   .w-33 {
    width: 100% ;
    float: none;
  }

  }


  /*-- Fix Default Layouts --*/

  /*-- One Col 100% --*/
  .ptl_col_1 { width: 100%; padding: 0; }
  .sideBar .ptl_col_1 { width: 100%; padding: 0%; }

  /*-- Two Col 50% --*/
  .ptl_col_1_2 { width: 48%; padding: 1%; }

  /*-- Two Coll 25% / 65% --*/
  .ptl_col_1_4 { width: 23%; padding: 1%; }
  .ptl_col_3_4 { width: 73%; padding: 1%; }

  /*-- Two Coll 66% / 33% --*/
  //.ptl_col_2_3 { width: 64%; padding: 1%; }
  //.ptl_col_1_3 { width: 31%; padding: 1%; }


  @media screen and (max-width: 750px) {
    .ptl_col_1_4 { width: 100%; padding: 0%; }
    .ptl_col_3_4 { width: 100%; padding: 0%; }
  }


  @media screen and (max-width: 580px) {
   .ptl_col_1 { width: 100%; padding: 0%; }

   .ptl_col_1_2 { width: 100%; padding: 0%; }

   .ptl_col_2_3 { width: 100%; padding: 0%; }
   .ptl_col_1_3 { width: 100%; padding: 0%; }
  }
}

.ptl_page
{
  @include clear-fix;
}