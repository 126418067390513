.Alert {
  border: 2px solid transparent;
  padding: 1em;
  font-weight: bold;
}
.Alert--error {
  border-color: red;
  color: red;
  background: rgba(red, 0.1);
}
.Alert--info {
  border-color: $gold;
  color: $color;
  background-color: rgba($gold, 0.1);
}
.Alert--success {
  border-color: green;
  color: green;
  background-color: rgba(green, 0.1);
}
