.GroupModal__fields {
  display: flex;
  flex-wrap: wrap;

  > p {
    width: 100%;
  }

  > * {
    flex-grow: 1;
  }

  > input,
  > button {
    margin: 0 0.5em;
  }
  @include mobile-only {
    font-size: 20px;
  }
}

.GroupModal__group-code {
  text-transform: uppercase;
}

.GroupModal__start-join-group,
.GameCardSettingsModal__apply {
  background: $gold;
  color: $color;
  border: none;
  box-shadow: 0 0 0 rgba(black, 0);

  &:hover {
    background: $gold;

    &:not(:active) {
      box-shadow: 0 0 1em rgba(black, 0.2);
    }
  }
}
