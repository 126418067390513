.Games .Games__add-a-game
{
    margin-top: 2em;
    border-top: 1px solid $color;
    padding: 1em 1em 1em 0;
    position: sticky;
    bottom: 0;
    background: white;
    padding-bottom: 1em;
}
.Games
{
    overflow: visible !important;
    display: flex; 
    //flex-wrap: wrap;
    flex-direction: column;
    @include animated;

    .GameList
    {
        flex-grow: 1;
        flex-shrink: 1;
        overflow-y: auto;
    }

    @media (max-width: $phone_width)
    {

        &:not(.Games--visible)
        {
            transform: translateX(-100%);
        }
    
        
    }
}
.Games__toggle-btn
{
    @include no-button-style;
    position: absolute;
    right: 0;
    top: 3em;
    transform: translateX(99%);
    z-index: 2; 
    color: $background_light;
    background: lighten($color,5%);

    @media (min-width: $phone_width + 1)
    {
        display: none;
    }
}
.Games__site-title
{
    display: flex;
    justify-content: space-between;
    padding-right: 1rem;

    .App__auth-button
    {
        font-size: 1rem;
        color: $background_light;
        border-color: $background_light;
    }
}