//@version 1.1

@import "variables";
@import "social-colors";
@import "mixins";
@import "normalize";
@import "grid/skeleton-grid.scss";
@import "presence-fixes";
@import "grid/grid";
@import "primitives";
@import "forms";
@import "utility-classes";
//@import "mmenu";
//@import "print";

@media screen and (prefers-reduced-motion : reduce) { 
    *
    {
        transition: none !important;
        animation: none !important;
    }
}