*
{
	box-sizing: border-box;
}
.RadWindow
{
	&, *
	{
		box-sizing: initial;
	}
}
.container
{
	width: 100%;
	max-width: $container_width;
	margin-left: auto;
	margin-right: auto;

	@media (max-width: $container_width)
	{
		padding-left: $gutter;
		padding-right: $gutter;
	}

	&.container--align-left
	{
		margin-left: 0;
	}

	&.container--align-right
	{
		margin-right: 0;
	}
}
.container--xl
{
    max-width: $container_xl_width;
}
.row
{
	position: relative;
	@include set-gutter();

	@if $flexbox != true
	{
		&:after
		{
			display: table;
			content: '';
			clear: both;
		}	
	}
	
	@if $flexbox == true {

		//@supports(display: flex)
		//{
			display: flex;
			flex-wrap: wrap;
			align-items: stretch;
			justify-content: flex-start;	

			&:after
			{
				display: inline;
				content: '';
				clear: none;
			}
		//}
	}
	
}

.row.no-gutter
{
	margin-left: 0 !important;
	margin-right: 0 !important;

	.column
	{
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

.column
{
	float: left;

	@if $flexbox == true 
	{
		//@supports (display: flex)
		//{
			flex-grow: 0;
			flex-shrink: 0;
			float: none;
		//}
		
	}

	&.pull-right
	{
		@if $flexbox == true 
		{
			margin-left: auto;
			float: none;
		} @else {
			float: right;
		}
	}

	&.pull-left
	{
		margin-right: auto;
	}
}

.container.table-container
{
	display: table;

	> .row
	{
		display: table-row;
	}

	> .row > .column
	{
		display: table-cell;
		float: none;
	}
}

@for $col from 1 through $num_cols
{
	.small-#{$col}
	{
		width: 100% * ($col / $num_cols);
	}
}

@media (max-width: $small_break)
{
	.small-#{$num_cols}
	{
		float: none;
	}

	.small-none,
	.small-hide
	{
		display: none;
	}

	.table-container > .row > .column.small-12
	{
		display: block;
	}
}

@for $col from 1 through $num_cols
{
	@media (min-width: $small_break + 1)
	{
		.mobile-#{$col}
		{
			width: 100% * ($col / $num_cols);
		}
	}
}

@for $col from 1 through $num_cols
{
	@media (min-width: $small_break + 1)
	{
		.medium-#{$col}
		{
			width: 100% * ($col / $num_cols);
		}
	}
}

@media (max-width: $medium_break) and (min-width: $small_break + 1)
{
	.medium-#{$num_cols}
	{
		float: none;
	}

	.medium-hide,
	.medium-none
	{
		display: none;
	}

	.table-container > .row > .column.medium-12
	{
		display: block;
	}
}

@for $col from 1 through $num_cols
{
	@media (min-width: $medium_break + 1)
	{
		.large-#{$col}
		{
			width: 100% * ($col / $num_cols);
		}
	}
}

@media (min-width: $medium_break + 1)
{
	.large-#{$num_cols}
	{
		float: none;
	}

	.large-none,
	.large-hide
	{
		display: none;
	}

	.table-container > .row > .column.large-12
	{
		display: block;
	}
}