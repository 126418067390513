.Toastify__toast.bingo {
  background: $gold;
}
.Toastify__toast-body {
  font-size: 20px;
  color: $color;

  em {
    color: $gold;

    span {
      color: $color;
    }
  }

  @include mobile-only {
    strong {
      color: $gold;
    }
  }
}

.Toastify__progress-bar {
  background: $gold;

  .Toastify__toast.bingo & {
    background: $color;
  }
}
