.GameCardSettingsModal__footer {
  display: flex;
  justify-content: flex-end;

  background-color: $background;
  > * {
    padding: 0.4em 1em;
    &:not(:first-child) {
      margin-left: 1em;
    }
  }

  position: fixed;
  left: calc(7px);
  bottom: 0;
  width: calc(100% - 15px);

  @supports (position: sticky) {
    margin-top: 1em;
    position: sticky;
    width: 100%;
    bottom: -2em;
    left: 0;
    padding-bottom: 2em;
    padding-top: 1em;
  }
}

.GameCardSettingsModal {
  @include mobile-only {
    height: 100vh;
  }
}
.GameCardSettingsModal .GridPicker {
  margin-top: 1em;
}

.GameCardSettingsModal button {
  background: rgba($color, 0.2);
  border: none;

  &.cta {
    background: $green;
  }
}
.GameCardSettingsModal__parent-rules-sliders {
  list-style: none;
  margin: 1em 0;
  padding: 0;
  display: flex;

  justify-content: space-between;
  flex-wrap: wrap;

  li {
    margin-bottom: 0.5em;
    width: 48%;
  }

  @include mobile-only {
    @media (orientation: portrait) {
      display: block;

      li {
        width: auto;
        font-size: 1.5em;
      }
    }
  }
}
.GameCardSettingsModal__grid-size,
.GameCardSettingsModal__scoring {
  width: calc(50% - 4px);
  float: left;
  @include mobile-only {
    @media (orientation: portrait) {
      width: 100%;
    }
  }
}

.GameCardSettingsModal__scoring dl {
  font-size: 0.9em;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  gap: 0.1em 0.5em;
  align-items: center;

  dt,
  dd {
    margin: 0;
  }
  dt {
    font-weight: bold;
    font-size: 1.6em;
    color: $gold;
  }
}
